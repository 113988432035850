import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthResetPasswordSendCodeRequestSchema } from './request';
import { AuthResetPasswordSendCodeResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthResetPasswordSendCodeRequestSchema extends ContractData {
*     email: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthResetPasswordSendCodeResponseSchema extends ContractData {
* }
*
* ```
*/
export class AuthResetPasswordSendCodeRouteRpc extends BaseRouteRpc {
  static method = 'auth-reset-password-send-code';

  static request: AuthResetPasswordSendCodeRequestSchema;
  static response: AuthResetPasswordSendCodeResponseSchema;

  static role = RoleType.guest;
}
