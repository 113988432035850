import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersStoreApplyingsApplyForShipStationStoreRequestSchema } from './request';
import { OrdersStoreApplyingsApplyForShipStationStoreResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersStoreApplyingsApplyForShipStationStoreRequestSchema extends ContractData {
*     data: Data;
* }
*
* export interface Data {
*     email?:    string;
*     storeName: string;
*     type:      Type;
* }
*
* export type Type = "connect" | "manual";
*
* ```
* Response interface:
*
* ```
* export interface OrdersStoreApplyingsApplyForShipStationStoreResponseSchema extends ContractData {
* }
*
* ```
*/
export class OrdersStoreApplyingsApplyForShipStationStoreRouteRpc extends BaseRouteRpc {
  static method = 'orders-store-applyings-apply-for-ship-station-store';

  static request: OrdersStoreApplyingsApplyForShipStationStoreRequestSchema;
  static response: OrdersStoreApplyingsApplyForShipStationStoreResponseSchema;

  static role = RoleType.unverified;
}
