import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthResetPasswordDoResetRequestSchema } from './request';
import { AuthResetPasswordDoResetResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthResetPasswordDoResetRequestSchema extends ContractData {
*     code:     string;
*     email:    string;
*     password: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthResetPasswordDoResetResponseSchema extends ContractData {
*     success: boolean;
* }
*
* ```
*/
export class AuthResetPasswordDoResetRouteRpc extends BaseRouteRpc {
  static method = 'auth-reset-password-do-reset';

  static request: AuthResetPasswordDoResetRequestSchema;
  static response: AuthResetPasswordDoResetResponseSchema;

  static role = RoleType.guest;
}
