import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileUpdateEmailRequestSchema } from './request';
import { AuthProfileUpdateEmailResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileUpdateEmailRequestSchema extends ContractData {
*     data:  Data;
*     token: string;
* }
*
* export interface Data {
*     email: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileUpdateEmailResponseSchema extends ContractData {
*     profile: Profile;
* }
*
* export interface Profile {
*     company?:  Company;
*     email:     string;
*     firstName: string;
*     id:        string;
*     lastName:  string;
*     phone:     string;
*     role:      number;
* }
*
* export interface Company {
*     name?:      string;
*     storeUrls?: string[];
*     url?:       string;
* }
*
* ```
*/
export class AuthProfileUpdateEmailRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-update-email';

  static request: AuthProfileUpdateEmailRequestSchema;
  static response: AuthProfileUpdateEmailResponseSchema;

  static role = RoleType.unverified;
}
