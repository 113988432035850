import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileEnterVerificationRequestSchema } from './request';
import { AuthProfileEnterVerificationResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileEnterVerificationRequestSchema extends ContractData {
*     token: string;
*     type:  Type;
* }
*
* export type Type = "password" | "email";
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileEnterVerificationResponseSchema extends ContractData {
* }
*
* ```
*/
export class AuthProfileEnterVerificationRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-enter-verification';

  static request: AuthProfileEnterVerificationRequestSchema;
  static response: AuthProfileEnterVerificationResponseSchema;

  static role = RoleType.guest;
}
