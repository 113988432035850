import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileUpdatePasswordRequestSchema } from './request';
import { AuthProfileUpdatePasswordResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileUpdatePasswordRequestSchema extends ContractData {
*     data:  Data;
*     token: string;
* }
*
* export interface Data {
*     password: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileUpdatePasswordResponseSchema extends ContractData {
* }
*
* ```
*/
export class AuthProfileUpdatePasswordRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-update-password';

  static request: AuthProfileUpdatePasswordRequestSchema;
  static response: AuthProfileUpdatePasswordResponseSchema;

  static role = RoleType.unverified;
}
