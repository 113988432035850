import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileSendVerificationRequestSchema } from './request';
import { AuthProfileSendVerificationResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileSendVerificationRequestSchema extends ContractData {
*     type: Type;
* }
*
* export type Type = "password" | "email";
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileSendVerificationResponseSchema extends ContractData {
* }
*
* ```
*/
export class AuthProfileSendVerificationRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-send-verification';

  static request: AuthProfileSendVerificationRequestSchema;
  static response: AuthProfileSendVerificationResponseSchema;

  static role = RoleType.guest;
}
