import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthResetPasswordApproveCodeRequestSchema } from './request';
import { AuthResetPasswordApproveCodeResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthResetPasswordApproveCodeRequestSchema extends ContractData {
*     code:  string;
*     email: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthResetPasswordApproveCodeResponseSchema extends ContractData {
*     success: boolean;
* }
*
* ```
*/
export class AuthResetPasswordApproveCodeRouteRpc extends BaseRouteRpc {
  static method = 'auth-reset-password-approve-code';

  static request: AuthResetPasswordApproveCodeRequestSchema;
  static response: AuthResetPasswordApproveCodeResponseSchema;

  static role = RoleType.guest;
}
